export const CURRENT = "current";
export const FUTURE = "future";

export const LOCATIONS = [
  {
    city: "Warszawa",
    link: "warszawa/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 70,
      top: 48,
    },
    price: "400",
    type: CURRENT,
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Warszawa+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
  },
  {
    city: "Poznań",
    price: "400",
    link: "poznan/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 29,
      top: 45,
    },
    type: CURRENT,
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Poznan+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
  },
  {
    city: "Wrocław",
    link: "wroclaw/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 30,
      top: 65,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Wroclaw+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
  },
  {
    city: "Kraków",
    link: "krakow/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 57,
      top: 89,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Krakow+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
  },
  {
    city: "Szczecin",
    link: "szczecin/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 7,
      top: 30,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Szczecin+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
  },

  {
    city: "Gdańsk",
    link: "gdansk/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 43,
      top: 11,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Gdansk+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
  },
  
  {
    city: "Katowice",
    link: "katowice/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 48,
      top: 82,
    },
    price: "400",
    giftCardLink: "https://sklep.przelewy24.pl/zakup.php?z24_id_sprzedawcy=109863&z24_kwota=40000&z24_currency=pln&z24_nazwa=Wideodermatoskopia+badanie+znamion&z24_opis=Katowiceon+Osoba+doros%B3a&z24_language=pl&k24_kraj=PL&z24_crc=1e346a65&",
    type: CURRENT,
  },

  
];